import React, { useState } from 'react';
import { errorToast, successToast } from '../../api/Util';
import ConfirmationDialog from '../ConfirmationDialog';
import { axiosClient } from '../../api/httpClient';

const EnableYtHelper = ({ SetCardYtHelperEnableModal, CardYtHelperEnableModal, currentCardData, setUpdatedYtHelperStatus }) => {

    const updateYtEnableHelper = () => {
        SetCardYtHelperEnableModal(false);
        let obj = {
            client_id: currentCardData?.client_id,
            card_id: currentCardData?.card_id,
        };
        axiosClient.post(`/api/card/${currentCardData?.card_id}/enableCardYtHelper`, obj)
            .then(({ data }) => {
                successToast("Card YtHelper Enabled successfully!!");
                setUpdatedYtHelperStatus(true)
            })
            .catch((err) => {
                errorToast("Something went wrong, please try again");
            })
    }

    return (
        <ConfirmationDialog
            onNegative={() => { SetCardYtHelperEnableModal(false) }}
            onPositive={() => { updateYtEnableHelper() }}
            confirmationHeader="Confirm"
            confirmationDialogId={"confirm-ytHelper-enable"}
            confirmation={CardYtHelperEnableModal}
            confirmationText={"Are you sure want to enable the ytHelper ?"}
        />
    );
}

export default EnableYtHelper;
