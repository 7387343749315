import React, { useEffect, useState } from 'react';
import AWS from 'aws-sdk';
import * as actions from "../actions";
import { connect, useSelector } from 'react-redux';
// eslint-disable-next-line no-unused-vars
import * as _ from 'lodash';
import Select from 'react-select';
import {
	Button,
	Col,
	Container,
	Icon,
	ProgressBar,
	Row,
	Table,
	TextInput,
} from "react-materialize";
import TopHeader from './TopHeader';
import { getAccessKeyValue, getSecretKeyValue } from '../helpers/commonHelper';
import { CUSTOMER } from '../api/Constants';
import CustomDropdown from './common/CustomDropdown/DropDown';
const digitalOceanSpacesBucket = process.env.REACT_APP_DIGITALOCEAN_S3_BUCKET;
const accessKeyId =  getAccessKeyValue("digitalOcean");
const secretAccessKey = getSecretKeyValue("digitalOcean");
const digitalOceanPublicUrl = process.env.REACT_APP_DIGITALOCEAN_PUBLIC_URL

const myBucket = new AWS.S3({
	params: { Bucket: digitalOceanSpacesBucket },
	endpoint: process.env.REACT_APP_DIGITALOCEAN_ENDPOINT,
	accessKeyId: accessKeyId,
	secretAccessKey: secretAccessKey,
	region: 'nyc3',
	// useAccelerateEndpoint: true
})

myBucket.putBucketCors({
	Bucket: digitalOceanSpacesBucket,
	CORSConfiguration: {
		CORSRules: [{
			AllowedOrigins: ['*'],
			AllowedMethods: ['*']
		}]
	}
});

export default connect(mapStateToProps, actions)(() => {

	const websiteUsers = useSelector(state => state.allWebsiteUsers.website_users);
	const [selectedFile, setSelectedFile] = useState(null);
	const [viewAs, setViewAs] = useState("");
	const [link, setLink] = useState([]);
	const [videoList, setVideoList] = useState("");
	const [progressBar, setProgressBar] = useState([]);
    const [options, setOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [toolTipText, setToolTipText] = useState("Copy to Clipboard");

	useEffect(() => {
		var option;
		option = (websiteUsers || [])?.filter((user)=> user.client_type === CUSTOMER).map(user => {
			return {
				value:`${user.client_id}` , 
				label: `${user.fullname} (${user.client_type})`
			}
		})
		setOptions(option);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [websiteUsers]);

	const handleFileInput = (e) => {
		setSelectedFile(e.target.files);
	}

	let progressInfos = [];

	const handleClick = (selectedFile) => {
		for (let i = 0; i < selectedFile.length; i++) {
			progressInfos.push({ percentage: 0, fileName: selectedFile[i].name });
			uploadFile(i, selectedFile[i]);
		}
	};

	let upload;

	const uploadFile = (i, file) => {
		setIsLoading(true);

		let uniqueTimestamp = Date.now().toString();
		let originalFileName = file.name.trim();
		let fileNameWithoutExtension = originalFileName.replace(/\.[^/.]+$/, "");
		let fileExtension = originalFileName.split('.').pop();
		fileNameWithoutExtension = fileNameWithoutExtension
				.replace(/[^\w\s-]/g, "")  // Remove special characters
				.replaceAll(" ", "-");     // Replace spaces with hyphens
		let uploadFileName = `${fileNameWithoutExtension}-${uniqueTimestamp}.${fileExtension}`;
		const params1 = {
			ACL: 'public-read',
			Body: file,
			Bucket: digitalOceanSpacesBucket,
			Key: `${viewAs}/${uploadFileName}`,
			// partSize: 10 * 1024 * 1024,
			// queueSize: 10,
		}


		upload = myBucket.upload(params1,
			(err, data) => {
				if (err) {
					setIsLoading(false);
					if (err.code === 'RequestAbortedError') {
						console.log("upload aborted")
					} else {
						console.log("upload failed")
					}
					return err;
				} else {
					setIsLoading(false);
					let temp = data?.Key ? `${digitalOceanPublicUrl}/${data.Key}` : null;
					// setLink(data?.Location);
					setLink(link => [...link, temp]);
				}
			}
		)
		upload.on('httpUploadProgress', (evt) => {
			let tempProgress = [...progressInfos];
			tempProgress[i].percentage = Math.round((evt.loaded / evt.total) * 100);
			setProgressBar(tempProgress);
		});

	}

	function listFile() {
		setIsLoading(true);
		const listParams = {
			// Bucket: S3_BUCKET,
			Bucket: digitalOceanSpacesBucket,
			Delimiter: "/",
			Prefix: `${viewAs}/`
		};

		myBucket.listObjects(listParams, (err, data) => {
			if (err) {
				setIsLoading(false);
				console.log(err);
			}

			if (data) {
				setIsLoading(false);
				setVideoList(data.Contents);
			}
		});
	}

    const handleCustomerTypeChange = (value) => {
            setViewAs(value);
      };

	return <Container id="video_upload">
		{isLoading ? <Row><ProgressBar /></Row> : ""}
		<div className="aside-right-top-bar">
			<div className="top-bar-left">
				<div className="top_bar">
					<h3>Upload Video</h3>
				</div>
			</div>
			<div className="top-bar-right">
				<TopHeader />
			</div>
		</div>
		<div className="upload-main">
			<div className="select-customer-profile">
				<Row>
					<Col s={12} m={3}>
						<CustomDropdown
						options={options}
						placeholder="Select Customer Profile"
						className="z-depth-1 select-view-as upload-customer-files"
						dropdownValue={viewAs}
						handleOnchange={handleCustomerTypeChange}
						/>
					</Col>
				</Row>
			</div>
			{
				progressBar && progressBar?.map((item, i) => {
					return <div className="row">
						<Col s={12} className="label-column11" >
							<label><strong className="blue-text">{`File Upload Progress for ${item.fileName}: `}</strong>{item.percentage}%</label>
						</Col>
					</div>
				})
			}
			<div className="select-video">
				<TextInput type="file" multiple onChange={handleFileInput} disabled={!viewAs} label="Select Video " />
			</div>
			<div className="upload-video-btns">
				<Button className="uploadbtn submit_btn" onClick={() => handleClick(selectedFile)} disabled={(!viewAs) || !selectedFile} style={{ margin: "5px" }} > Upload Video</Button>
				<Button className="uploadbtn submit_btn" onClick={listFile} disabled={!viewAs} style={{ margin: "5px" }} > List of videos</Button>
			</div>
		</div>
		<Row>
			{
				link && link?.map((item, i) => {
					return <Col s={12} className="label-column11 after_upload" >
						<label> <strong className="blue-text uploaded-video-link">{`Uploaded Video Link ${i + 1} : `}</strong> {item}</label>
						<Button icon={<Icon>content_copy</Icon>} className="text-copy-btn" tooltip={toolTipText} onClick={() => { navigator.clipboard.writeText(item); setToolTipText("Copied") }} />
					</Col>
				})
			}
		</Row>
		{
			videoList && videoList.length > 0 &&
			<Table className="highlight">
				<thead>
					<tr>
						<th>Serial Number</th>
						<th>Video</th>
						<th>Last Modified</th>
						<th>Link</th>
						<th>Copy</th>
					</tr>
				</thead>
				<tbody>
					{
						videoList.sort((a, b) => new Date(b.LastModified) - new Date(a.LastModified))
					     .map((videos, i) => {
							let p = videos.Key.search("/");
							return (
								<tr key={"table-key-" + i}>
									<td>{i + 1}</td>
									<td>{videos.Key.slice(p + 1)}</td>
									<td>{videos.LastModified.toString()}</td>
									{/* <td>{"https://vidchops-videos.s3-accelerate.amazonaws.com/"+videos.Key}</td> */}
									<td>{`${digitalOceanPublicUrl}/${videos.Key}`}</td>

									<td>
										{<Button icon={<Icon>content_copy</Icon>} className="text-copy-btn"
											onClick={() => {
												navigator.clipboard.writeText(
													// "https://vidchops-videos.s3-accelerate.amazonaws.com/"+videos.Key
													`${digitalOceanPublicUrl}/${videos.Key}`
												)
											}} />}
									</td>
								</tr>
							)
						})
					}
				</tbody>
			</Table>
		}
		{
			videoList && videoList.length === 0 &&
			<Col s={12} className="label-column11 no-uploaded-videos-found" >
				<label>No Uploaded Videos Found </label>
			</Col>
		}
	</Container>
});

function mapStateToProps({ cards, auth, users, settings }) {
	return { cards, auth, users, settings };
};
