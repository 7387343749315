import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Redirect, useParams} from "react-router-dom";
import dayjs from 'dayjs';
import {
    Button,
    Card,
    Container,
    Icon,
    Row,
    Select,
    TextInput,
    Table,
    ProgressBar,
    Tabs,
    Tab,
    Col,
} from "react-materialize";
import Pagination from "react-js-pagination";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import {axiosClient} from "../api/httpClient";
import {
    errorToast,
    finishToast,
    getUserOptions,
    infoToast,
    isAdmin,
    isCustomer,
    isEditor,
    modifiedOrNull,
    parseSqlDateTime,
    successToast,
    toISODate,
    updateToast,
    exportTableToCSV,
    calculateTimeDifference,
    convertToPhilippinesTimezone,
    rangePresets,
} from "../api/Util";
import * as _ from 'lodash';
import ConfirmationDialog from "./ConfirmationDialog";
import CardModal from "./CardModal";
import {
    ADMIN,
    CUSTOM,
    CUSTOMER,
    DOUBLE,
    QA,
    QA_USER,
    SINGLE,
    TEAM_LEAD,
    UNLIMITED,
    WEEKLY,
    YT_HELPER,
    YT_HELPER_LEAD,
    YT_HELPER_SECTION
} from "../api/Constants";
import CustomDatePicker from "./common/DatePicker";
import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import LeaveManagement from "./LeaveManagment/leaveManagment";
import LeaveManagementTable from "./LeaveManagment/LeaveManagmentTable";
import { getLeaveHistory } from "../services/LeaveManagementService";
import { exportCommentsFromCard } from "../services/userService";
import CSATTable from "./CSAT/CSATTable";

const INITIAL_STATE = {
    firstName: "",
    lastName: "",
    email: "",
    secondaryEmail: "",
    type: "",
    dropboxEmail: "",
    password: "",
    teamLead: "0",
    assignQa: ["0", "0"],
    userProfile: {},
    billingDate: new Date(0),
    doneVideoLimit:1,
    subscriptionType: SINGLE,
    customSubscription: "",
    subtitles: "0",
    hasYoutubeHelper: "0",
    teamNote: "",
    youtubeHelper: "0",
    preferredEditingSoftware: "",
    timezone: "",
    referral: "Other",
    otherReferral: "",
    videoType: "",
    aboutVideo: "",
    socialMediaLink: "",
    videoStyle: "",
    videoInspired: "",
    videoInfo: "",
    additionalVideoInfo: "",

    channelName:"",
    prevThumbnailsLink:[""],
    refThumbnailsFile:"",
    thumbnailFont:"",
    thumbnailMainColor:"",
    thumbnailSubColor:"",
    descriptionLink:[""],
    photoPreference:"",
    textPreference:"",
    titlePreference:"",
    endCardPreference:"",
    publicVideo:"",
    keywordList:"",
    additionalThumbnailData:"",
};

export default connect(mapStateToProps)((props) => {
    const {userId} = useParams();
    const queryParams = new URLSearchParams(window.location.search);
    const startDateQueryParam = queryParams.get('startDate');
    const endDateQueryParam = queryParams.get('endDate');
    if(props?.auth?.loggedInUser?.client_id !== userId && isCustomer(props?.auth?.loggedInUser)) {
        return <Redirect to="/" />
    }
    const cantEdit = !isAdmin(props?.auth?.loggedInUser) && userId !== props?.auth?.loggedInUser?.client_id;
    const [state, setState]
        = useState(INITIAL_STATE);
    const [queryParamsValue, setQueryParams] = useState({
        queryStartDate: startDateQueryParam ? moment(decodeURIComponent(startDateQueryParam)).toDate() : null,
        queryEndDate: endDateQueryParam ? moment(decodeURIComponent(endDateQueryParam)).toDate() : null,
        queryUserType:queryParams.get('userType')});

    const {firstName, lastName, email, password, secondaryEmail, type, teamLead, dropboxEmail, userProfile, assignQa,
        billingDate,doneVideoLimit: doneVideoNo, subscriptionType, customSubscription, subtitles, hasYoutubeHelper, youtubeHelper, teamNote,
        preferredEditingSoftware, timezone} = state;
    const [disableTeamLead, setDisableTeamLead] = useState(false);
    const [updateDisabled, setUpdateDisabled] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const dateDiff = props.auth.loggedInUser.client_type === CUSTOMER ? -29 : -7;
    const [activeTab, setActiveTab] = useState(
        queryParamsValue?.queryUserType === 'QaTab' ? 
        'RESPONSE RATE' : 
        queryParamsValue?.queryUserType === 'CSAT' ? 
        'CSAT' : 
        'BRIEF INFO'
      );
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [responseRateData, setResponseRateData] = useState([]);
    const [rowsCount, setRowsCount] = useState();
    const [commentsCsvData, setCommentsCsvData] = useState([]);
    const [viewCardId, setViewCardId] = useState({ cardId: 0, section: null });
    const [viewCardOpen, setViewCardOpen] = useState(false);
    const [startDate, setStartDate] = useState(queryParamsValue?.queryStartDate || addDays(new Date(), dateDiff));
    const [endDate, setEndDate] = useState(queryParamsValue?.queryEndDate || new Date());    
    const [addDateRangeModalOpen, setAddDateRangeModalOpen] = useState(false);
    const [viewAs, setViewAsState] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [paginatedData, setPaginatedData] = useState([]);
    //for showing assign customer column in table
    const [showAssignCustomer, setShowAssignCustomer] = useState(true);
    const [leaveHistoryData, setLeaveHistoryData] = useState([]);
    const [leaveDataLoaded, setLeaveDataLoaded] = useState(false);
    const userProfileData = state?.userProfile;
    const userProfileDataForLeave = {
        firstname: userProfileData?.firstname,
        lastname: userProfileData?.lastname,
        client_type: userProfileData?.client_type,
        client_id: userProfileData?.client_id,
        assigned_to_lead_id: userProfileData?.assigned_to_lead_id
    };
    const limit = 10;
    const me = props.auth.loggedInUser;

    function addDays(date, days) {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    const refreshUser = () => {
        const toastId = infoToast("Loading user data", 0.0);
        axiosClient.get('/api/user/' + userId, {
            onDownloadProgress: (pE) => updateToast(toastId.current, pE.loaded / (pE.total || 10000))
        }).then(response => {
            let user = response.data;
            finishToast(toastId.current);

            let ytHelper = (user.assignments || [])
                .filter(assignment => assignment.client_type === YT_HELPER || assignment.client_type === YT_HELPER_LEAD)
                .map(assignment => `${assignment.client_id}`)
                // .concat(Array(1).fill("0")).slice(0,1)[0];
                .concat(Array(2).fill("0")).slice(0,2);

            let assigned_customers =[];
            //if user is qa
            if(user.customers)
            {
                setShowAssignCustomer(false);
                assigned_customers = user.customers;
            }
            //if user is team lead or yt leam lead
            if(user.team_lead_qas)
            {
                assigned_customers = [...assigned_customers, ...user.team_lead_qas];
            }
            //if user is yt helper
            if(user.assigned_customers)
            {
                assigned_customers = [...assigned_customers,...user.assigned_customers];
            }

            setState({
                firstName: user.firstname,
                lastName: user.lastname,
                email: user.useremail,
                secondaryEmail: user.secondary_email || "",
                type: user.client_type,
                dropboxEmail: user.dropbox_email || "",
                password: "",
                teamLead: `${user.assigned_to_lead_id}`,
                userProfile: user,
                assignQa: (user.qas || []).map(qa => `${qa.client_id}`).concat(Array(3).fill("0")).slice(0,3),
                billingDate: parseSqlDateTime(user.billing_date),
                subscriptionType: user.subscription_type,
                customSubscription: user.custom_subscription,
                subtitles: `${user.has_subtitles}`,
                hasYoutubeHelper: `${user.has_youtube_helper}`,
                youtubeHelper: ytHelper,
                teamNote: user.team_notes,
                preferredEditingSoftware: user.preferred_editing_software || "",
                timezone: user.timezone || "",
                referral: user.referral,
                otherReferral: user.otherreferral,
                videoType: user.video_type,
                aboutVideo: user.about_video,
                socialMediaLink: user.social_media_link,
                videoStyle: user.video_style,
                videoInspired: user.video_inspired,
                videoInfo: user.video_info,
                additionalVideoInfo: user.additional_video_info,
                assignUsers : assigned_customers ? assigned_customers : null,
                doneVideoLimit: user.done_video_limit,

                // for yt helper fields data
                channelName: user.channel_name ,
                prevThumbnailsLink: user.prev_thumbnails_link && user.prev_thumbnails_link !== "" ? user.prev_thumbnails_link.split('|'):[""],
                refThumbnailsFile: user.ref_thumbnails_file,
                thumbnailFont: user.thumbnail_font,
                thumbnailMainColor: user.thumbnail_main_color,
                thumbnailSubColor: user.thumbnail_sub_color,
                descriptionLink: user.description_link && user.description_link !== "" ? user.description_link.split('|'):[""],
                photoPreference: user.photo_preference,
                textPreference: user.text_preference,
                titlePreference: user.title_preference,
                endCardPreference: user.end_card_preference,
                publicVideo: user.public_video,
                keywordList:user.keyword_list,
                additionalThumbnailData:user.additional_thumbnail_data,
            });
            setState(prev => ({ ...prev, type: user.client_type}));

            if (user.mailPreferences) {
                setState(prev => ({...prev, mailPreferences: user.mailPreferences}));
            }

        }).catch(err => {
            errorToast(`Unable to load user: ${err.message}`);
            console.error(err);
        });
    };

    const clearQueryParams = () => {
        const newUrl = window.location.pathname;  
        window.history.replaceState({}, '', newUrl);
    };
    
    useEffect(() => {
        if (!props.users.users && !props.users.loadingUsers) {
            props.fetchAllUsers();
            // return (
            //     <ProgressBar/>
            // );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(() => {
        setState(INITIAL_STATE);
        setDisableTeamLead(false);
        refreshUser();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    function onUserTypeChange(e) {
        const {value} = e.target;
        setState(prev => ({...prev, teamLead: null, type: value}));
        if(isEditor({client_type: value})) {
            setDisableTeamLead(false)
        } else {
            setDisableTeamLead(true)
        }
    }

    function onClickUpdate() {
        setConfirmation(true);
    }

    async function updateUser() {
        setUpdateDisabled(true);
        let data = {
            firstname: modifiedOrNull(firstName, userProfile.firstname),
            lastname: modifiedOrNull(lastName, userProfile.lastname),
            email: modifiedOrNull(email, userProfile.useremail),
            secondary_email: modifiedOrNull(secondaryEmail, userProfile.secondary_email),
            client_type: modifiedOrNull(type, userProfile.client_type),
            dropbox_email: modifiedOrNull(dropboxEmail, userProfile.dropbox_email),
            password: (password != null && password !== "") ? password : null,
            timezone: modifiedOrNull(timezone, userProfile.timezone),
        };
        let extras;
        if(isCustomer(userProfile)) {
            extras = {
                assign_qa: assignQa.filter(qa => qa && qa !== "0").map(Number),
                billing_date: modifiedOrNull(toISODate(billingDate), userProfile.billing_date),
                subscription_type: modifiedOrNull(subscriptionType, userProfile.subscription_type),
                custom_subscription: modifiedOrNull(customSubscription, userProfile.custom_subscription),
                has_subtitles: modifiedOrNull(subtitles, `${userProfile.has_subtitles}`),
                has_youtube_helper: modifiedOrNull(hasYoutubeHelper, `${userProfile.has_youtube_helper}`),
                assign_yt_helper: youtubeHelper.filter(yt => yt && yt !== "0").map(Number),
                team_note: modifiedOrNull(teamNote, userProfile.team_note),
                preferred_editing_software: modifiedOrNull(preferredEditingSoftware, userProfile.preferred_editing_software),
                referral: modifiedOrNull(state.referral, userProfile.referral),
                otherreferral: modifiedOrNull(state.otherReferral, userProfile.otherreferral),
                video_type: modifiedOrNull(state.videoType, userProfile.video_type),
                about_video:  modifiedOrNull(state.aboutVideo, userProfile.about_video),
                social_media_link:  modifiedOrNull(state.socialMediaLink, userProfile.social_media_link),
                video_style:  modifiedOrNull(state.videoStyle, userProfile.video_style),
                video_inspired:  modifiedOrNull(state.videoInspired, userProfile.video_inspired),
                video_info:  modifiedOrNull(state.videoInfo, userProfile.video_info),
                additional_video_info:  modifiedOrNull(state.additionalVideoInfo, userProfile.additional_video_info),
                done_video_limit: modifiedOrNull(state.doneVideoLimit,userProfile.done_video_limit),

                // for yt helper fields data
                channel_name: modifiedOrNull(state.channelName, userProfile.channel_name),
                prev_thumbnails_link: modifiedOrNull(state.prevThumbnailsLink, userProfile.prev_thumbnails_link),
                ref_thumbnails_file: modifiedOrNull(state.refThumbnailsFile, userProfile.ref_thumbnails_file),
                thumbnail_font: modifiedOrNull(state.thumbnailFont, userProfile.thumbnail_font),
                thumbnail_main_color: modifiedOrNull(state.thumbnailMainColor, userProfile.thumbnail_main_color),
                thumbnail_sub_color: modifiedOrNull(state.thumbnailSubColor, userProfile.thumbnail_sub_color),
                description_link: modifiedOrNull(state.descriptionLink, userProfile.description_link),
                photo_preference: modifiedOrNull(state.photoPreference, userProfile.photo_preference),
                text_preference: modifiedOrNull(state.textPreference, userProfile.text_preference),
                title_preference: modifiedOrNull(state.titlePreference, userProfile.title_preference),
                end_card_preference: modifiedOrNull(state.endCardPreference, userProfile.end_card_preference),
                public_video: modifiedOrNull(state.publicVideo, userProfile.public_video),
                keyword_list:modifiedOrNull(state.keywordList, userProfile.keyword_list),
                additional_thumbnail_data:modifiedOrNull(state.additionalThumbnailData, userProfile.additional_thumbnail_data),
            };
            if (state.mailPreferences) {
                extras = {...extras, mailPreferences: state.mailPreferences};
            }
        } else {
            extras = {
                assigned_to_lead_id: teamLead,
            };
        }
        data = _.assign({}, data, extras);
        data = _.pickBy(data, _.identity);

        data = {...data,updatedFromProfile:true};

        axiosClient.put('/api/user/' + userId, data).then(() => {
            successToast(`User ${userProfile.firstname} ${userProfile.lastname} updated`);
        }).catch(err => {
            errorToast(err.response.data.error_message || 'Something went wrong: ' + err.message);
        }).finally(() => {
            setUpdateDisabled(false);
            setConfirmation(false);
        });
    }

    function onChange(e) {
        const {name, value} = e.target;
        setState(prev => ({...prev, [name]: value}));
    }

    const handleSubType = (subType,customSub) => {
        switch (subType) {
            case SINGLE:
                return 'SINGLE';
            case WEEKLY:
                return 'WEEKLY';
            case UNLIMITED:
                return 'UNLIMITED';
            case DOUBLE:
                return 'DOUBLE';
            case CUSTOM:
                return `CUSTOM (${customSub})`;
            default:
                return "-";
        }
    }

    function handleActiveTab(e) {
        const tag = e?.target?.innerText;
        setPage(1);
        setActiveTab(tag);
    }
    const handlePageChange = (pageNumber) => {
        const offset = (pageNumber - 1) * itemsPerPage;
        setPaginatedData(responseRateData.slice(offset, offset + itemsPerPage));
        setCurrentPage(pageNumber);
    };

    const fetchResponseRateData = () => {
        if (activeTab === 'RESPONSE RATE' || activeTab === 'YT HELPER RESPONSE RATE') {
            if(loading) {
                return;
            }
            setLoading(true);
            infoToast("Loading");
            setResponseRateData([]);
            setRowsCount();
            let commonReponseRateApi = '/api/user/' + userId+'/responseRateV2';
            let ytHelperReponseRateApi = '/api/ythelper/user/' + userId+'/responseRate';
            axiosClient.get(activeTab === 'YT HELPER RESPONSE RATE' ? ytHelperReponseRateApi : commonReponseRateApi, {
              id: userId,
              params: {
                type: activeTab === 'YT HELPER RESPONSE RATE' ? 'yt_helper_comments' : 'comments',
                start_date: queryParamsValue?.queryStartDate ? toISODate(queryParamsValue?.queryStartDate) : toISODate(startDate),
                end_date:   queryParamsValue?.queryEndDate ? toISODate(queryParamsValue?.queryEndDate): toISODate(endDate),
                view_as: viewAs,
                profileType: queryParamsValue?.queryUserType === 'TeamLeadTab' ? 'team-lead' :
                queryParamsValue?.queryUserType === 'QaTab' ? 'qa' : type
              }
            }).then(response => {
                clearQueryParams();
              setResponseRateData(response.data.data);
              setPaginatedData(response.data.data.slice(0, itemsPerPage)); // Initialize paginated data
              setRowsCount(response.data.count);
            }).catch(err => {
              errorToast(`Unable to load response rate: ${err}`);
              console.error(err);
            }).finally(() => setLoading(false));
        }
    }
    useEffect(() => {
        if (startDate && endDate) {
          fetchResponseRateData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [activeTab, page, viewAs, startDate, endDate]);
  
      const getLeaveData = async () => {
        if (activeTab === 'LEAVE MANAGMENT' && !leaveDataLoaded) {
            setLoading(true);
            try {
                const result = await getLeaveHistory(userId);
                if (result.status) {
                    setLeaveHistoryData(result?.data);
                    setLoading(false);
                    setLeaveDataLoaded(true);
                }
            } catch (error) {
                console.error('Error fetching leave history:', error);
                setLoading(false); 
            }
        }
    };
  

    useEffect(() => {
        getLeaveData();
    }, [activeTab]);

    async function exportComments(item) {
        let extraParams =  {
            tab:activeTab,
            queryParams:queryParamsValue,
            type:type,
            startDate:toISODate(startDate),
            endDate:toISODate(endDate)
        }
        const { status, data, error } = await exportCommentsFromCard(item, extraParams);
        if (status) {
            const comments = data.map(note => {
                const sanitizedNote = note.note?.replace(/"/g, "'");
                const isValidDate = (dateStr) => {
                    return dateStr && dateStr !== "0000-00-00 00:00:00";
                };
                return {
                    cardTitle: type === QA ? item.card_title : note.card_title,
                    note: sanitizedNote,
                    repliedNote: note.repliedComment,
                    ...(activeTab === 'RESPONSE RATE' && {
                        noteAdded: convertToPhilippinesTimezone(note.date_added),
                        noteUpdated: note.note_reaction_date_time < note.updated_at ? !isValidDate(note.note_reaction_date_time) ? '-' : convertToPhilippinesTimezone(note.note_reaction_date_time) : !isValidDate(note.updated_at) ? '-' : convertToPhilippinesTimezone(note.updated_at),
                        timeDifference: (!isValidDate(note.date_added) || !isValidDate(note.note_reaction_date_time) && !isValidDate(note.updated_at)) ? '-' : calculateTimeDifference(note.date_added, (note.note_reaction_date_time && note.updated_at) ? (note.note_reaction_date_time < note.updated_at ? note.note_reaction_date_time : note.updated_at) : (note.note_reaction_date_time || note.updated_at)) 
                    })
                };
            });
            setCommentsCsvData(comments);
            exportTableToCSV('comments-table', `Comments.csv`);
        } else {
            errorToast(`Unable to load comments: ${error}`);
        }
    }

    const showCard = (cardId, selectedSection) => {
        let section = null;
        if (selectedSection === 'YT HELPER RESPONSE RATE') {
           section = YT_HELPER_SECTION;
        }
        setViewCardId({ cardId: cardId, section });
        setViewCardOpen(true);
    }

    const handleDateRange = (dates) => {
        setStartDate(new Date(dates[0]));
        setEndDate(new Date(dates[1]));
    }

    function getAssignedClientList(customers){
         return customers.map((customer) => (
                <option key={`${customer.client_type}-${customer.client_id}`} value={`${customer.client_id}`}>
                    {customer.fullname ? customer.fullname : `${customer.firstname} ${customer.lastname}`}
                </option>
            ));
    };

    return (
        <>
        {
            (type === QA || type === TEAM_LEAD) ? 
            <Card id="user_profile_page" actions={activeTab === "BRIEF INFO" ? [
                <Button key="refresh-btn" onClick={e => refreshUser(e)} node="button" waves="green" large flat>
                    Reload <Icon right>refresh</Icon>
                </Button>,
                <Button key="update-btn" onClick={() => onClickUpdate()} disabled={updateDisabled} node="button" waves="green" className="btn-primary" large>
                    Update <Icon right>save</Icon>
                </Button>
            ] : null}>
            <CardModal cardId={viewCardId.cardId} isOpen={viewCardOpen} onClose={setViewCardOpen.bind(this, false)} section={viewCardId.section}
                qas={props.users.qas} users={props.users.users} me={me} />
            <Tabs className="user-tabs" onChange={handleActiveTab}>
                <Tab title="Brief Info">
                    <Container id="response-rate-table">
                        <ConfirmationDialog
                            onNegative={() => setConfirmation(false)}
                            onPositive={updateUser}
                            confirmationHeader="Confirmation"
                            confirmationDialogId="userUpdateConfirmation"
                            confirmation={confirmation}
                            confirmationText="Are you sure you want to update this user profile?"
                        />
                        <Row>
                            <TextInput s={12} m={6} id="txtFirstName" name="firstName" label="First Name" icon="person"
                                    value={firstName} onChange={onChange} disabled={cantEdit}/>
                            <TextInput s={12} m={6} id="txtLastName" name="lastName" label="Last Name" icon="person"
                                    value={lastName} onChange={onChange} disabled={cantEdit}/>
                        </Row>
                        <Row>
                            <TextInput s={12} m={6} id="txtEmail" name="email" label="Email" email validate icon="email"
                                    value={email} onChange={onChange} disabled={cantEdit}/>
                            <TextInput s={12} m={6} id="txtPassword" name="password" label="Password" icon="lock"
                                    value={password} onChange={onChange} disabled={cantEdit}/>
                        </Row>
                        <Row>
                            <TextInput s={12} m={6} id="txtSecondaryEmail" name="secondaryEmail" label="Assistant Email" email validate icon="email"
                                    value={secondaryEmail} onChange={onChange} disabled={cantEdit}/>
                                    {/* Change from "What is your Dropbox Email" */}
                            <TextInput s={12} m={6} id="txtDropboxEmail" name="dropboxEmail" label="What is your Gdrive Email?" icon="email"
                                    value={dropboxEmail} onChange={onChange} email validate disabled={cantEdit}/>
                        </Row>
                        <Row>
                            <Select s={12} m={6} disabled={cantEdit}
                                    icon={<Icon>assignment</Icon>}
                                    id="clientType"
                                    name="clientType"
                                    key="select-client-type"
                                    label="User Type"
                                    value={type}
                                    onChange={onUserTypeChange}>
                                <option value="">
                                    User Type
                                </option>
                                <option value={YT_HELPER}>
                                    Youtube Helper
                                </option>
                                <option value={QA_USER}>
                                    QA
                                </option>
                                <option value={TEAM_LEAD}>
                                    Team Lead
                                </option>
                                <option value={YT_HELPER_LEAD}>
                                    Youtube Helper Team Lead
                                </option>
                                <option value={ADMIN}>
                                    Admin
                                </option>
                            </Select>
                            <Select s={12} m={6} name="teamLead"
                                    icon={<Icon>supervisor_account</Icon>}
                                    id="assignTeamLead"
                                    label="Team Lead"
                                    value={teamLead}
                                    disabled={disableTeamLead || cantEdit}
                                    onChange={onChange}>
                                <option value="0">
                                    None
                                </option>
                                {getUserOptions(props.users.users, TEAM_LEAD)}
                                {getUserOptions(props.users.users, YT_HELPER_LEAD)}
                            </Select>
                        </Row>
                        <Row>
                        {props.auth.loggedInUser.client_type === 'admin' && (
                        <LeaveManagement
                         userId={userId}
                         getLeaveData={getLeaveData}
                         setActiveTab={setActiveTab}
                         setLeaveDataLoaded={setLeaveDataLoaded}
                         userData={userProfileDataForLeave}
                        />
                        )}
                        </Row>
                        {
                            state && state.assignUsers && state.assignUsers.length > 0 &&
                            <Table className = "highlight">
                                <thead>
                                    <tr>
                                        <th>SR. Number</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Client Type</th>
                                        {!showAssignCustomer ? <th>Status</th> : null }
                                        {!showAssignCustomer ? <th>Subscription Type</th> : null }
                                        {showAssignCustomer?<th>Assign Customers</th>:null}
                                        <th>Activity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        state.assignUsers.map((assignUser,i)=>{
                                            let diffInTime = new Date().getTime() - new Date(assignUser.last_login_time).getTime();
                                            let diffInDays = diffInTime / (1000 * 3600 * 24);
                                            return (
                                                <tr key={'customer-table-row'+i}>
                                                    <td>{i+1}</td>
                                                    <td>{assignUser.firstname + " "+ assignUser.lastname}</td>
                                                    <td>{assignUser.useremail}</td>
                                                    <td>{assignUser.client_type}</td>

                                                    { !showAssignCustomer ? <td>{ assignUser.is_active_subscription ? "Active":"Inactive"}</td> : null }
                                                    {!showAssignCustomer ? <td>
                                                        {
                                                            handleSubType(assignUser.subscription_type,assignUser.custom_subscription)
                                                        }
                                                    </td> : null}
                                                    {showAssignCustomer ? <td>{assignUser.customers && assignUser.customers.length ? assignUser.customers.length : 
                                                            assignUser.assigned_customers ? assignUser.assigned_customers.length : "0" }</td>:null}
                                                    {assignUser.last_login_time ? <td>{
                                                        diffInDays > 30 ? "Inactive for 30 Days" : diffInDays > 14 ? "Inactive for 14 Days" : "Active"
                                                        }</td> : <td>-</td>}
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        }
                </Container>
                </Tab>
                <Tab title="Response Rate" active={queryParamsValue?.queryUserType === 'QaTab' ? activeTab === 'RESPONSE RATE' : false}>
                    <Container id="response-rate-table">
                        <Row>
                            <Col s={3}>
                                <Select
                                    value={viewAs}
                                    onChange={(e) => setViewAsState(e.target.value)}
                                    placeholder="View As"
                                >
                                <option value="all">
                                    All
                                </option>
                                {getAssignedClientList(state.assignUsers)}
                                </Select>
                            </Col>
                            <Col className="response-rate-date-picker">
                               <CustomDatePicker  className="datePicker mobileMode" setStartDate={setStartDate} setEndDate={setEndDate} startDate={startDate} endDate={endDate} />
                            </Col>
                        </Row>
                        <Table className = "highlight">
                            <thead>
                                <tr>
                                    <th>SR. Number</th>
                                    {type === QA && <th>Card Title</th>}
                                    <th>{type === QA ? "Customer Name" : "QA Name"}</th>
                                    <th>Comments Count</th>
                                    <th>Replied Count</th>
                                    <th>Response Rate</th>
                                    <th>Export</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedData.length > 0 ? (
                                    paginatedData.map((item, index) => {
                                        const rowIndex = ((currentPage - 1) * itemsPerPage) + index + 1;
                                        return (
                                            <tr key={'customer-table-row'+index}>
                                                <td>{rowIndex}</td>
                                                {type === QA && (
                                                <Link className="linkTextStyle" to={`/card-details/${item.card_id}`}  target="_blank">
                                                <td className="response-rate-card-title">{item.card_title}</td></Link>)}
                                                <td>{`${item.first_name || ''} ${item.last_name || ''}`}</td>
                                                <td>{item.total_comments_count}</td>
                                                <td>{item.replied_count}</td>
                                                {type === QA && <td>{item.response_rate ? parseFloat(item?.response_rate)?.toFixed(2) + '%' : 0}</td>}
                                                {type === TEAM_LEAD && <td>{item.response_rate ? (item.response_rate/item.total_response_rated_cards) > 100 ? 100+'%' : (item.response_rate/item.total_response_rated_cards)?.toFixed(2) + '%' : 0}</td>}
                                                {type === QA && <td>{item.total_comments_count ?
                                                <Button className="export-comments-icon" onClick={() => exportComments(item)}><Icon>save</Icon></Button>
                                                    : '-'}
                                                </td>}
                                                {type === TEAM_LEAD && <td>{item.total_comments_count ?
                                                <Button className="export-comments-icon" onClick={() => exportComments(item)}><Icon>save</Icon></Button>
                                                    : '-'}
                                                </td>}
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan="7" style={{ textAlign: 'center' }}>No Data Found</td>
                                    </tr>
                                )}
                                </tbody>

                        </Table>
                        <Table  id="comments-table">
                            <thead>
                                <tr>
                                    <th>CardTitle</th>
                                    <th>Note</th>
                                    <th>Replied Note</th>
                                    {activeTab === 'RESPONSE RATE' && <>
                                    <th>Note Added (PHT)</th>
                                    <th>Note Updated (PHT)</th>
                                    <th>Time Difference</th>
                                     </>}
                                </tr>
                            </thead>
                            <tbody>
                                {commentsCsvData.map((item, i) => <tr key={i+1 + "-row"}>
                                            <td>{item.cardTitle}</td>
                                            <td>{item.note}</td>
                                            {item.repliedNote.length > 0 ? item.repliedNote.map(note => <td>{note}</td>)
                                                : <td>{item.repliedNote}</td>}
                                             {activeTab === 'RESPONSE RATE' && <>
                                                <td>{item.noteAdded}</td>   
                                                <td>{item.noteUpdated}</td>
                                                <td>{item.timeDifference}</td>
                                              </>}
                                        </tr>)
                                }
                            </tbody>
                        </Table>
                    </Container>
                </Tab>
                <Tab title="CSAT" active={queryParamsValue?.queryUserType === 'CSAT' ? activeTab === 'CSAT' : false}>
                    <CSATTable 
                     dateDiff={dateDiff}
                     addDays={addDays}
                     activeTab={activeTab}
                     type={type}
                    />
                </Tab>
                <Tab title="YT Helper Response Rate">
                    <Container id="response-rate-table">
                        <Row>
                            <Col s={3}>
                                <Select
                                    value={viewAs}
                                    onChange={(e) => setViewAsState(e.target.value)}
                                    placeholder="View As"
                                >
                                <option value="all">
                                    All
                                </option>
                                {getAssignedClientList(state.assignUsers)}
                                </Select>
                            </Col>
                            <Col className="response-rate-date-picker">
                                <CustomDatePicker  className="datePicker mobileMode" setStartDate={setStartDate} setEndDate={setEndDate} startDate={startDate} endDate={endDate} />
                            </Col>
                        </Row>
                        <Table className = "highlight">
                            <thead>
                                <tr>
                                    <th>SR. Number</th>
                                    <th>Card Title</th>
                                    <th>Customer Name</th>
                                    <th>Comments Count</th>
                                    <th>Replied Count</th>
                                    <th>Response Rate</th>
                                    <th>Export</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    responseRateData.length > 0 ? responseRateData.map((item,i)=>{
                                        return (
                                            <tr key={'customer-table-row'+i}>
                                                <td>{((page - 1) * limit) + (i+1)}</td>
                                                <td className="response-rate-card-title" onClick={() => item.card_id ? showCard(item.card_id, activeTab) : null}>{item.card_title}</td>
                                                <td>{`${item.first_name || ''} ${item.last_name || ''}`}</td>
                                                <td>{item.total_comments_count}</td>
                                                <td>{item.replied_count}</td>
                                                {type === QA && <td>{item.response_rate ? item.response_rate + '%' : 0}</td>}
                                                {type === TEAM_LEAD && <td>{item.response_rate ? (item.response_rate/item.total_response_rated_cards) > 100 ? 100+'%' : (item.response_rate/item.total_response_rated_cards) + '%' : 0}</td>}
                                                {type === QA && <td>{item.total_comments_count ?
                                                <Button className="export-comments-icon" onClick={() => exportComments(item)}><Icon>save</Icon></Button>
                                                    : '-'}
                                                </td>}
                                                {type === TEAM_LEAD && <td>{item.total_comments_count ?
                                                <Button className="export-comments-icon" onClick={() => exportComments(item)}><Icon>save</Icon></Button>
                                                    : '-'}
                                                </td>}
                                            </tr>
                                        )
                                    }) : <tr>
                                            <td colspan="6" style={{ textAlign: 'center' }}>No Data Found</td>
                                        </tr>
                                }
                            </tbody>
                        </Table>
                    </Container>
                </Tab>
                <Tab title="Leave managment">
                    <LeaveManagementTable 
                    leaveHistoryData={leaveHistoryData}
                    getLeaveData={getLeaveData}
                    loggedInUser={props.auth.loggedInUser.client_type}
                    setLeaveDataLoaded={setLeaveDataLoaded}
                    />
            </Tab>
            </Tabs>
            <Row className="center-align">
                {
                    activeTab !== 'BRIEF INFO' && rowsCount > 10 ?
                        <Row className="center-align">
                            <Pagination
                            activePage={currentPage}
                            itemsCountPerPage={itemsPerPage}
                            totalItemsCount={rowsCount || 0}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                            prevPageText={<AiOutlineLeft/>}
                            nextPageText={<AiOutlineRight/>}
                            firstPageText={<AiOutlineDoubleLeft/>}
                            lastPageText={<AiOutlineDoubleRight/>}
                          />
                        </Row> : null
                }
            </Row>
            </Card> : <Card id="user_profile_page" actions={[
                <Button key="refresh-btn" onClick={e => refreshUser(e)} node="button" waves="green" large flat>
                    Reload <Icon right>refresh</Icon>
                </Button>,
                <Button key="update-btn" onClick={() => onClickUpdate()} disabled={updateDisabled} node="button" waves="green" className="btn-primary" large>
                    Update <Icon right>save</Icon>
                </Button>
            ]}>
                    <Container id="response-rate-table">
                    <ConfirmationDialog
                        onNegative={() => setConfirmation(false)}
                        onPositive={updateUser}
                        confirmationHeader="Confirmation"
                        confirmationDialogId="userUpdateConfirmation"
                        confirmation={confirmation}
                        confirmationText="Are you sure you want to update this user profile?"
                    />
                    <Row>
                        <TextInput s={12} m={6} id="txtFirstName" name="firstName" label="First Name" icon="person"
                                value={firstName} onChange={onChange} disabled={cantEdit}/>
                        <TextInput s={12} m={6} id="txtLastName" name="lastName" label="Last Name" icon="person"
                                value={lastName} onChange={onChange} disabled={cantEdit}/>
                    </Row>
                    <Row>
                        <TextInput s={12} m={6} id="txtEmail" name="email" label="Email" email validate icon="email"
                                value={email} onChange={onChange} disabled={cantEdit}/>
                        <TextInput s={12} m={6} id="txtPassword" name="password" label="Password" icon="lock"
                                value={password} onChange={onChange} disabled={cantEdit}/>
                    </Row>
                    <Row>
                        <TextInput s={12} m={6} id="txtSecondaryEmail" name="secondaryEmail" label="Assistant Email" email validate icon="email"
                                value={secondaryEmail} onChange={onChange} disabled={cantEdit}/>
                                {/* Change from "What is your Dropbox Email" */}
                        <TextInput s={12} m={6} id="txtDropboxEmail" name="dropboxEmail" label="What is your Gdrive Email?" icon="email"
                                value={dropboxEmail} onChange={onChange} email validate disabled={cantEdit}/>
                    </Row>
                    <Row>
                        <Select s={12} m={6} disabled={cantEdit}
                                icon={<Icon>assignment</Icon>}
                                id="clientType"
                                name="clientType"
                                key="select-client-type"
                                label="User Type"
                                value={type}
                                onChange={onUserTypeChange}>
                            <option value="">
                                User Type
                            </option>
                            <option value={YT_HELPER}>
                                Youtube Helper
                            </option>
                            <option value={QA_USER}>
                                QA
                            </option>
                            <option value={TEAM_LEAD}>
                                Team Lead
                            </option>
                            <option value={YT_HELPER_LEAD}>
                                Youtube Helper Team Lead
                            </option>
                            <option value={ADMIN}>
                                Admin
                            </option>
                        </Select>
                        <Select s={12} m={6} name="teamLead"
                                icon={<Icon>supervisor_account</Icon>}
                                id="assignTeamLead"
                                label="Team Lead"
                                value={teamLead}
                                disabled={disableTeamLead || cantEdit}
                                onChange={onChange}>
                            <option value="0">
                                None
                            </option>
                            {getUserOptions(props.users.users, TEAM_LEAD)}
                            {getUserOptions(props.users.users, YT_HELPER_LEAD)}
                        </Select>
                    </Row>
                    {
                        state && state.assignUsers && state.assignUsers.length > 0 &&
                        <Table className = "highlight">
                            <thead>
                                <tr>
                                    <th>SR. Number</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Client Type</th>
                                    {!showAssignCustomer ? <th>Status</th> : null }
                                    {!showAssignCustomer ? <th>Subscription Type</th> : null }
                                    {showAssignCustomer?<th>Assign Customers</th>:null}
                                    <th>Activity</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    state.assignUsers.map((assignUser,i)=>{
                                        let diffInTime = new Date().getTime() - new Date(assignUser.last_login_time).getTime();
                                        let diffInDays = diffInTime / (1000 * 3600 * 24);
                                        return (
                                            <tr key={'customer-table-row'+i}>
                                                <td>{i+1}</td>
                                                <td>{assignUser.firstname + " "+ assignUser.lastname}</td>
                                                <td>{assignUser.useremail}</td>
                                                <td>{assignUser.client_type}</td>

                                                { !showAssignCustomer ? <td>{ assignUser.is_active_subscription ? "Active":"Inactive"}</td> : null }
                                                {!showAssignCustomer ? <td>
                                                    {
                                                        handleSubType(assignUser.subscription_type,assignUser.custom_subscription)
                                                    }
                                                </td> : null}
                                                {showAssignCustomer ? <td>{assignUser.customers && assignUser.customers.length ? assignUser.customers.length : 
                                                        assignUser.assigned_customers ? assignUser.assigned_customers.length : "0" }</td>:null}
                                                {assignUser.last_login_time ? <td>{
                                                    diffInDays > 30 ? "Inactive for 30 Days" : diffInDays > 14 ? "Inactive for 14 Days" : "Active"
                                                    }</td> : <td>-</td>}
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    }
                </Container>
            </Card>
        }
        </>
    );
});

function mapStateToProps({users, auth}) {
    return {users, auth};
}
